// src/components/PurchaseFlow/Steps/DataEntry/Sections/Referrals.js
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const vinculos = ['Conyuge', 'Familiar', 'Amigo', 'Compañero de trabajo', 'Vecino', 'Otro'];

const Referrals = forwardRef((props, ref) => {
  const {
    formData,
    handleChange,
    activeSection,
    sectionName,
    setSectionValidity,
    sectionValidity,
    handleSectionClick,
  } = props;
  const [errors, setErrors] = useState({});

  const validateSection = () => {
    const newErrors = {};
    [1, 2].forEach((i) => {
      if (!formData[`referidoNombre${i}`]) newErrors[`referidoNombre${i}`] = 'El nombre es requerido';
      if (!formData[`referidoApellido${i}`]) newErrors[`referidoApellido${i}`] = 'El apellido es requerido';
      if (!formData[`referidoTelefono${i}`]) newErrors[`referidoTelefono${i}`] = 'El teléfono es requerido';
      if (!formData[`referidoVinculo${i}`]) newErrors[`referidoVinculo${i}`] = 'El vínculo es requerido';
    });

    setErrors(newErrors);

    const isValid = Object.keys(newErrors).length === 0;
    setSectionValidity((prev) => ({ ...prev, Referrals: isValid })); // Cambiado a 'Referrals'
    return isValid;
  };

  useImperativeHandle(ref, () => ({
    validateSection,
  }));

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading${sectionName}`}>
        <button
          className={`accordion-button ${activeSection !== 'Referrals' ? 'collapsed' : ''}`}
          type="button"
          onClick={() => handleSectionClick('Referrals')}
        >
          Referidos{' '}
              {sectionValidity[sectionName] === true && (
                <FontAwesomeIcon icon={faCheckCircle} className="text-success ms-2" />
              )}
              {sectionValidity[sectionName] === false && (
                <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger ms-2" />
              )}
        </button>
      </h2>
      <div
          className={`accordion-collapse collapse ${activeSection === sectionName ? 'show' : ''}`}
          aria-labelledby={`heading${sectionName}`}
      >
        <div className="accordion-body">
          {[1, 2].map((i) => (
            <div className="row" key={i}>
              <h5>Referido {i}</h5>
              <div className="col-md-6 mb-3">
                <label className="form-label">Nombre *</label>
                <input
                  type="text"
                  className={`form-control ${errors[`referidoNombre${i}`] ? 'is-invalid' : ''}`}
                  name={`referidoNombre${i}`}
                  value={formData[`referidoNombre${i}`] || ''}
                  onChange={handleChange}
                  required
                />
                {errors[`referidoNombre${i}`] && (
                  <div className="invalid-feedback">{errors[`referidoNombre${i}`]}</div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Apellido *</label>
                <input
                  type="text"
                  className={`form-control ${errors[`referidoApellido${i}`] ? 'is-invalid' : ''}`}
                  name={`referidoApellido${i}`}
                  value={formData[`referidoApellido${i}`] || ''}
                  onChange={handleChange}
                  required
                />
                {errors[`referidoApellido${i}`] && (
                  <div className="invalid-feedback">{errors[`referidoApellido${i}`]}</div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Teléfono *</label>
                <input
                  type="tel"
                  className={`form-control ${errors[`referidoTelefono${i}`] ? 'is-invalid' : ''}`}
                  name={`referidoTelefono${i}`}
                  value={formData[`referidoTelefono${i}`] || ''}
                  onChange={handleChange}
                  required
                />
                {errors[`referidoTelefono${i}`] && (
                  <div className="invalid-feedback">{errors[`referidoTelefono${i}`]}</div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Vínculo *</label>
                <select
                  className={`form-control ${errors[`referidoVinculo${i}`] ? 'is-invalid' : ''}`}
                  name={`referidoVinculo${i}`}
                  value={formData[`referidoVinculo${i}`] || ''}
                  onChange={handleChange}
                  required
                >
                  <option value="">Seleccione un vínculo</option>
                  {vinculos.map((vinculo, index) => (
                    <option key={index} value={vinculo}>
                      {vinculo}
                    </option>
                  ))}
                </select>
                {errors[`referidoVinculo${i}`] && (
                  <div className="invalid-feedback">{errors[`referidoVinculo${i}`]}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Referrals;
