// src/components/PurchaseFlow/Steps/DataEntry/Sections/AddressContact.js
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const provinces = [
    'Buenos Aires', 'Ciudad Autónoma de Buenos Aires', 'Catamarca', 'Chaco',
    'Chubut', 'Córdoba', 'Corrientes', 'Entre Ríos', 'Formosa', 'Jujuy',
    'La Pampa', 'La Rioja', 'Mendoza', 'Misiones', 'Neuquén', 'Río Negro',
    'Salta', 'San Juan', 'San Luis', 'Santa Cruz', 'Santa Fe',
    'Santiago del Estero', 'Tierra del Fuego', 'Tucumán'
];
  
const AddressContact = forwardRef((props, ref) => {
const {
    formData,
    handleChange,
    activeSection,
    sectionName,
    setSectionValidity,
    sectionValidity,
    handleSectionClick,
} = props;

    const [errors, setErrors] = useState({});

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
          case 'domicilio':
            if (!value) {
              error = 'El domicilio es requerido';
            }
            break;
          case 'provincia':
            if (!value) {
              error = 'La provincia es requerida';
            }
            break;
          case 'localidad':
            if (!value) {
              error = 'La localidad es requerida';
            }
            break;
          case 'codigoPostal':
            if (!value) {
              error = 'El código postal es requerido';
            }
            break;
          case 'telefonoCelular':
            if (!value) {
              error = 'El teléfono celular es requerido';
            }
            break;
          case 'email':
            if (!value) {
              error = 'El email es requerido';
            }
            break;
          default:
            break;
        }
    
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error,
        }));
    
        const newErrors = {
          ...errors,
          [name]: error,
        };
    
        const isValid = Object.values(newErrors).every((err) => !err);
        setSectionValidity((prev) => ({ ...prev, [sectionName]: isValid }));
    };

    const validateSection = () => {
        const fieldsToValidate = ['domicilio', 'provincia', 'localidad', 'codigoPostal', 'telefonoCelular'];
        let newErrors = {};
        fieldsToValidate.forEach((field) => {
          const value = formData[field];
          if (!value) {
            newErrors[field] = 'Este campo es requerido';
          }
        });
    
        setErrors(newErrors);
    
        const isValid = Object.keys(newErrors).length === 0;
        setSectionValidity((prev) => ({ ...prev, [sectionName]: isValid }));
        return isValid;
    };

    useImperativeHandle(ref, () => ({
      validateSection,
    }));
  
    return (
      <div className="accordion-item">
        <h2 className="accordion-header" id={`heading${sectionName}`}>
        <button
            className={`accordion-button ${activeSection !== sectionName ? 'collapsed' : ''}`}
            type="button"
            onClick={() => handleSectionClick(sectionName)}
          >
            Domicilio y Contacto{' '}
              {sectionValidity[sectionName] === true && (
                <FontAwesomeIcon icon={faCheckCircle} className="text-success ms-2" />
              )}
              {sectionValidity[sectionName] === false && (
                <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger ms-2" />
              )}
          </button>
        </h2>
        <div
          className={`accordion-collapse collapse ${activeSection === sectionName ? 'show' : ''}`}
          aria-labelledby={`heading${sectionName}`}
        >
          <div className="accordion-body">
            <div className="row">
              {/* Campos con validación */}
              <div className="col-md-6 mb-3">
                <label className="form-label">Calle *</label>
                <input
                  type="text"
                  className={`form-control ${errors.domicilio ? 'is-invalid' : ''}`}
                  name="domicilio"
                  value={formData.domicilio || ''}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  required
                />
                {errors.domicilio && <div className="invalid-feedback">{errors.domicilio}</div>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Provincia *</label>
                <select
                  className={`form-control ${errors.provincia ? 'is-invalid' : ''}`}
                  name="provincia"
                  value={formData.provincia || ''}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  required
                >
                  <option value="">Seleccione una provincia</option>
                  {provinces.map((prov, index) => (
                    <option key={index} value={prov}>{prov}</option>
                  ))}
                </select>
                {errors.provincia && <div className="invalid-feedback">{errors.provincia}</div>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Localidad *</label>
                <input
                  type="text"
                  className={`form-control ${errors.localidad ? 'is-invalid' : ''}`}
                  name="localidad"
                  value={formData.localidad || ''}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  required
                />
                {errors.localidad && <div className="invalid-feedback">{errors.localidad}</div>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Código Postal *</label>
                <input
                  type="text"
                  className={`form-control ${errors.codigoPostal ? 'is-invalid' : ''}`}
                  name="codigoPostal"
                  value={formData.codigoPostal || ''}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  required
                />
                {errors.codigoPostal && <div className="invalid-feedback">{errors.codigoPostal}</div>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Teléfono Celular *</label>
                <input
                  type="tel"
                  className={`form-control ${errors.telefonoCelular ? 'is-invalid' : ''}`}
                  name="telefonoCelular"
                  value={formData.telefonoCelular || ''}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  required
                />
                {errors.telefonoCelular && <div className="invalid-feedback">{errors.telefonoCelular}</div>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Email *</label>
                <input
                  type="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  name="email"
                  value={formData.email || ''}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  required
                />
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

export default AddressContact;