// src/components/PaymentFlow/InstallmentRow.js

import React, { useState, useEffect } from 'react';

const InstallmentRow = ({ loanId, installment, index, onInstallmentChange }) => {
  const [isChecked, setIsChecked] = useState(installment.isChecked);
  const [amountToPay, setAmountToPay] = useState(installment.amountToPay);
  const [inputDisabled, setInputDisabled] = useState(installment.inputDisabled);
  const [checkboxDisabled, setCheckboxDisabled] = useState(installment.checkboxDisabled);
  const [inputError, setInputError] = useState(installment.inputError);

  useEffect(() => {
    setIsChecked(installment.isChecked);
    setAmountToPay(installment.amountToPay);
    setInputDisabled(installment.inputDisabled);
    setCheckboxDisabled(installment.checkboxDisabled);
    setInputError(installment.inputError);
  }, [installment]);  

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    const amountDue = parseFloat(installment.amountDue);
    if (checked) {
      setAmountToPay(amountDue);
    } else {
      setAmountToPay('');
    }
    onInstallmentChange(index, {
      isChecked: checked,
      amountToPay: checked ? amountDue : '',
    });
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmountToPay(value);
    const amount = parseFloat(value) || 0;
    const amountDue = parseFloat(installment.amountDue);

    let error = false;
    if (amount > amountDue) {
      error = true;
    }
    setInputError(error);

    onInstallmentChange(index, {
      amountToPay: value,
      inputError: error,
    });
  };

  return (
    <tr>
      <td>{installment.installmentNumber}</td>
      <td>{installment.dueDate}</td>
      <td>${installment.amount}</td>
      <td>${installment.paymentsMade}</td>
      {/* <td>${installment.penalties}</td> */}
      <td>${installment.amountDue}</td>
      <td>
        <input
          className="form-check-input"
          type="checkbox"
          checked={isChecked}
          disabled={checkboxDisabled}
          onChange={handleCheckboxChange}
        />
      </td>
      <td>
        <input
          type="number"
          className={`form-control monto-a-pagar ${inputError ? 'input-error' : ''}`}
          value={amountToPay}
          disabled={inputDisabled}
          onChange={handleAmountChange}
          max={installment.amountDue}
          placeholder="Monto a pagar"
        />
        {inputError && (
          <small className="error-message text-danger">
            El monto excede el monto pendiente
          </small>
        )}
      </td>
    </tr>
  );
};

export default InstallmentRow;
