// src/components/PaymentFlow/PaymentFlow.js

import React, { useState, useEffect } from 'react';
import LoanList from './LoanList';
import PaymentSummary from './PaymentSummary';
import PaymentMethods from './PaymentMethods';
import './PaymentFlow.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const PaymentFlow = ({ clientData, onClose }) => {
  const [loans, setLoans] = useState([]);
  const [showQRModal, setShowQRModal] = useState(false);
  const [showCashModal, setShowCashModal] = useState(false);
  const [selectedInstallments, setSelectedInstallments] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [amountToDistribute, setAmountToDistribute] = useState('');
  const [cashReceived, setCashReceived] = useState(0);
  const [changeDue, setChangeDue] = useState(0);

  // Load loans (you can replace this with an API call)
  useEffect(() => {
    const initialLoans = [
      {
        id: '12345',
        issueDate: '01/01/2024',
        currentBalance: 5000,
        totalBalance: 12000,
        installments: [
          {
            installmentNumber: 1,
            dueDate: '15/01/2024',
            amount: 1000,
            paymentsMade: 500,
            penalties: 50,
            amountDue: 550,
          },
          {
            installmentNumber: 2,
            dueDate: '15/02/2024',
            amount: 1000,
            paymentsMade: 0,
            penalties: 0,
            amountDue: 1000,
          },
          {
            installmentNumber: 3,
            dueDate: '15/03/2024',
            amount: 1000,
            paymentsMade: 0,
            penalties: 0,
            amountDue: 1000,
          },
        ],
      },
      {
        id: '455612',
        issueDate: '12/06/2024',
        currentBalance: 9000,
        totalBalance: 12000,
        installments: [
          {
            installmentNumber: 1,
            dueDate: '15/07/2024',
            amount: 1000,
            paymentsMade: 500,
            penalties: 50,
            amountDue: 550,
          },
          {
            installmentNumber: 2,
            dueDate: '15/08/2024',
            amount: 1000,
            paymentsMade: 0,
            penalties: 0,
            amountDue: 1000,
          },
          {
            installmentNumber: 3,
            dueDate: '15/09/2024',
            amount: 1000,
            paymentsMade: 0,
            penalties: 0,
            amountDue: 1000,
          },
        ],
      },
    ];
    setLoans(initialLoans);
  }, []);

  // Update total amount whenever selectedInstallments changes
  useEffect(() => {
    const total = selectedInstallments.reduce((sum, item) => sum + parseFloat(item.amount), 0);
    setTotalAmount(total);
  }, [selectedInstallments]);

  // Handle installment selection
  const handleInstallmentSelection = (loanId, installmentIndex, amount) => {
    setSelectedInstallments((prev) => {
      const newInstallments = [...prev];
      const existingIndex = newInstallments.findIndex(
        (item) => item.loanId === loanId && item.installmentIndex === installmentIndex
      );

      if (amount > 0) {
        if (existingIndex >= 0) {
          newInstallments[existingIndex].amount = amount;
        } else {
          const loan = loans.find((l) => l.id === loanId);
          const installment = loan.installments[installmentIndex];
          newInstallments.push({
            loanId,
            installmentIndex,
            installment,
            amount,
          });
        }
      } else {
        if (existingIndex >= 0) {
          newInstallments.splice(existingIndex, 1);
        }
      }
      return newInstallments;
    });
  };

  const handleConfirmPayment = () => {
    if (paymentMethod === 'qr') {
      setShowQRModal(true);
    } else if (paymentMethod === 'cash') {
      setShowCashModal(true);
    } else {
      MySwal.fire({
        title: 'Pago Confirmado',
        text: 'El pago ha sido procesado exitosamente.',
        icon: 'success',
      }).then(() => {
        onClose();
      });
    }
  };

  // Función para redondear al siguiente múltiplo de 1000
  const roundToNextThousand = (amount) => {
    return Math.ceil(amount / 1000) * 1000;
  };

  // Manejar cambios en el monto recibido en efectivo
  const handleCashReceivedChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setCashReceived(value);
    setChangeDue(value - totalAmount);
  };

  // Confirmar pago en efectivo
  const confirmCashPayment = () => {
    MySwal.fire({
      title: 'Pago en Efectivo',
      text: 'El pago ha sido registrado exitosamente.',
      icon: 'success',
    }).then(() => {
      setShowCashModal(false);
      onClose();
    });
  };

  // Confirmar pago con QR
  const confirmQRPayment = () => {
    MySwal.fire({
      title: 'Pago con QR',
      text: 'El pago ha sido registrado exitosamente.',
      icon: 'success',
    }).then(() => {
      setShowQRModal(false);
      onClose();
    });
  };

  // Efecto para inicializar valores cuando se muestra el modal de efectivo
  useEffect(() => {
    if (showCashModal) {
      const roundedAmount = roundToNextThousand(totalAmount);
      setCashReceived(roundedAmount);
      setChangeDue(roundedAmount - totalAmount);
    }
  }, [showCashModal]);

  // Distribute amount entered by the user
  const distributeAmount = () => {
    resetFields();

    const amount = parseFloat(amountToDistribute);
    if (isNaN(amount) || amount <= 0) {
      MySwal.fire({
        icon: 'warning',
        title: 'Datos inválidos',
        text: 'Por favor, ingresa un monto válido para distribuir.',
      });
      return;
    }

    let allInstallments = [];
    loans.forEach((loan) => {
      loan.installments.forEach((installment, index) => {
        allInstallments.push({
          loanId: loan.id,
          installmentIndex: index,
          installment,
        });
      });
    });

    allInstallments.sort((a, b) => {
      const dateA = new Date(a.installment.dueDate.split('/').reverse().join('-'));
      const dateB = new Date(b.installment.dueDate.split('/').reverse().join('-'));
      return dateA - dateB;
    });

    const totalPending = allInstallments.reduce(
      (sum, item) => sum + parseFloat(item.installment.amountDue),
      0
    );
    if (amount > totalPending) {
      MySwal.fire({
        icon: 'warning',
        title: 'Datos inválidos',
        text: 'El monto a distribuir es mayor al total pendiente.',
      });
      return;
    }

    let remainingAmount = amount;
    const newSelectedInstallments = [];

    for (let item of allInstallments) {
      if (remainingAmount <= 0) break;
      const { loanId, installmentIndex, installment } = item;
      const amountDue = parseFloat(installment.amountDue);
      let amountToPay = 0;

      if (remainingAmount >= amountDue) {
        amountToPay = amountDue;
        remainingAmount -= amountDue;
      } else {
        amountToPay = remainingAmount;
        remainingAmount = 0;
      }

      newSelectedInstallments.push({
        loanId,
        installmentIndex,
        installment,
        amount: amountToPay,
      });
    }

    setSelectedInstallments(newSelectedInstallments);

    const updatedLoans = loans.map((loan) => {
      const updatedInstallments = loan.installments.map((installment, index) => {
        const selected = newSelectedInstallments.find(
          (item) => item.loanId === loan.id && item.installmentIndex === index
        );
        return {
          ...installment,
          amountToPay: selected ? selected.amount : '',
          isChecked: !!selected,
          inputDisabled: selected ? false : index !== 0,
          checkboxDisabled: selected ? false : index !== 0,
        };
      });
      return {
        ...loan,
        installments: updatedInstallments,
      };
    });

    setLoans(updatedLoans);
  };

  // Reset all fields before distributing amount
  const resetFields = () => {
    setSelectedInstallments([]);
    const updatedLoans = loans.map((loan) => {
      const updatedInstallments = loan.installments.map((installment, index) => {
        return {
          ...installment,
          amountToPay: '',
          isChecked: false,
          inputDisabled: index !== 0,
          checkboxDisabled: index !== 0,
        };
      });
      return {
        ...loan,
        installments: updatedInstallments,
      };
    });
    setLoans(updatedLoans);
  };

  return (
    <div id="main-content pt-0">
      <section className="section">
        <div className="row m-0">
          {/* Tarjeta principal que contiene los préstamos */}
          <div className="col-9 ps-0">
            <div className="container mt-0">
              <div className="card">
                <div className="card-header p-3">
                  <div className="row mt-3">
                    <div className="col-6">
                      <h5>Deuda Vencida: $ 5.000</h5>
                    </div>
                    <div className="col-6 pe-1">
                      <div className="float-right">
                        <div className="input-group">
                          <input type="number" id="amountToDistribute" className="form-control" placeholder="Ingrese monto" value={amountToDistribute} onChange={(e) => setAmountToDistribute(e.target.value)} />
                          <button className="btn btn-primary" onClick={distributeAmount}> Distribuir </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body position-relative p-2">
                  {/* Lista de préstamos con scroll */}
                  <div
                    className="overflow-auto"
                    style={{ maxHeight: '400px', paddingBottom: '80px' }}
                  >
                    {/* Cargar préstamos */}
                    <LoanList
                      loans={loans}
                      onInstallmentSelection={handleInstallmentSelection}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Resumen de pago en el lado derecho */}
          <div className="col-3 pe-0 ps-0">
            <div id="payment-summary" className="card">
              <div className="card-header text-center">
                <h4 className="mb-0">Resumen de Pago</h4>
              </div>
              <div className="card-body">
                {/* Resumen de las cuotas */}
                <PaymentSummary selectedInstallments={selectedInstallments} />
                {/* Métodos de pago */}
                <PaymentMethods
                  selectedMethod={paymentMethod}
                  onMethodSelect={setPaymentMethod}
                />
                {/* Monto total y botón de confirmación */}
                <div className="mt-3 text-center">
                  <h6>
                    Total a Pagar:{' '}
                    <span id="totalPaymentSummary" className="text-primary">
                      ${totalAmount.toFixed(2)}
                    </span>
                  </h6>
                  <button
                    id="confirmPaymentBtn"
                    className={`btn btn-success w-100 mt-3 ${
                      totalAmount === 0 || !paymentMethod ? 'btn-disabled' : ''
                    }`}
                    disabled={totalAmount === 0 || !paymentMethod}
                    onClick={handleConfirmPayment}
                  >
                    Confirmar Pago
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Modal de Pago con QR */}
      {showQRModal && (
        <>
          {/* Backdrop */}
          <div className="modal-backdrop fade show"></div>

          {/* Modal */}
          <div
            className="modal fade show"
            tabIndex="-1"
            style={{ display: 'block' }}
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Pago con QR</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowQRModal(false)}
                  ></button>
                </div>
                <div className="modal-body text-center">
                  <p>Monto a pagar: ${totalAmount.toFixed(2)}</p>
                  <img
                    src="/assets/compiled/png/qr-demo.png"
                    width={300}
                    alt="QR Code"
                  />
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    onClick={() => setShowQRModal(false)}
                  >
                    Cancelar
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={confirmQRPayment}
                  >
                    Confirmar Pago
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Modal de Pago en Efectivo */}
      {showCashModal && (
        <>
          <div className="modal-backdrop fade show"></div>

          {/* Modal */}
          <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} aria-modal="true" role="dialog" >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirmar Pago en Efectivo</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowCashModal(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <p>Monto a pagar: ${totalAmount.toFixed(2)}</p>
                  <div className="mb-3">
                    <label htmlFor="cashReceived" className="form-label">
                      Monto recibido en efectivo:
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="cashReceived"
                      value={cashReceived}
                      onChange={handleCashReceivedChange}
                      min={totalAmount}
                    />
                  </div>
                  <p>
                    Vuelto a entregar: ${changeDue >= 0 ? changeDue.toFixed(2) : '0.00'}
                  </p>
                  {changeDue < 0 && (
                    <p className="text-danger">
                      El monto recibido es menor al total a pagar.
                    </p>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    onClick={() => setShowCashModal(false)}
                  >
                    Cancelar
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={confirmCashPayment}
                    disabled={cashReceived < totalAmount}
                  >
                    Confirmar Pago
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentFlow;
