// src/components/PaymentFlow/LoanCard.js

import React, { useState, useEffect } from 'react';
import InstallmentRow from './InstallmentRow';

const LoanCard = ({ loan, onInstallmentSelection }) => {
  const [expanded, setExpanded] = useState(false);

  // Inicializa el estado de las cuotas
  const [installmentsState, setInstallmentsState] = useState([]);

  useEffect(() => {
    setInstallmentsState(
      loan.installments.map((installment, index) => ({
        ...installment,
        isChecked: installment.isChecked || false,
        amountToPay: installment.amountToPay || '',
        inputDisabled: installment.inputDisabled !== undefined ? installment.inputDisabled : index !== 0,
        checkboxDisabled: installment.checkboxDisabled !== undefined ? installment.checkboxDisabled : index !== 0,
        inputError: false,
      }))
    );
  }, [loan]);

  const toggleInstallments = () => {
    setExpanded(!expanded);
  };

  const handleInstallmentChange = (index, newState) => {
    setInstallmentsState((prev) => {
      const updated = prev.map((installment, idx) => {
        if (idx === index) {
          return { ...installment, ...newState };
        }
        return { ...installment };
      });
  
      // Lógica para habilitar/deshabilitar cuotas siguientes
      if (
        parseFloat(updated[index].amountToPay) >= parseFloat(updated[index].amountDue) &&
        !updated[index].inputError
      ) {
        if (index + 1 < updated.length) {
          updated[index + 1] = {
            ...updated[index + 1],
            inputDisabled: false,
            checkboxDisabled: false,
          };
        }
      } else {
        // Deshabilitar todas las cuotas siguientes
        for (let i = index + 1; i < updated.length; i++) {
          updated[i] = {
            ...updated[i],
            inputDisabled: true,
            checkboxDisabled: true,
            isChecked: false,
            amountToPay: '',
          };
        }
      }
  
      return updated;
    });
  };  

  // Whenever installmentsState changes, update the selections
  useEffect(() => {
    installmentsState.forEach((installment, index) => {
      onInstallmentSelection(loan.id, index, parseFloat(installment.amountToPay) || 0);
    });
  }, [installmentsState]);

  return (
    <div
      className={`card mb-3 border border-primary shadow-sm loan-card ${
        expanded ? 'expanded' : ''
      }`}
    >
      <div
        className="card-header d-flex justify-content-between p-2 align-items-center"
        onClick={toggleInstallments}
      >
        <div className="col-6 text-left">
          <h6 className="text-primary">
            <strong>Préstamo #{loan.id}</strong>
          </h6>
          <p className="mb-0">
            <strong>Fecha de Emisión:</strong> {loan.issueDate}
          </p>
        </div>
        <div className="col-5">
          <p className="mb-0">
            <strong>Saldo Actual:</strong> ${loan.currentBalance}
          </p>
          <p className="mb-0">
            <strong>Saldo Total:</strong> ${loan.totalBalance}
          </p>
        </div>
        <div>
          <i className={`toggle-icon bi ${expanded ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
        </div>
      </div>
      {expanded && (
        <div className="card-body">
          <div className="form-group">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Venc.</th> {/* Abreviado para "Vencimiento" */}
                  <th>Monto</th>
                  <th>Pagos</th> {/* Abreviado para "Pagos Realizados" */}
                  {/* <th>Penal.</th>  Abreviado para "Penalidades", si decides agregarlo de nuevo */}
                  <th>Pendiente</th> {/* Abreviado para "Monto Pendiente" */}
                  <th>Sel.</th> {/* Abreviado para "Seleccionar" */}
                  <th>A Pagar</th> {/* Abreviado para "Monto a Pagar" */}
                </tr>
              </thead>
              <tbody>
                {installmentsState.map((installment, index) => (
                  <InstallmentRow
                    key={index}
                    loanId={loan.id}
                    installment={installment}
                    index={index}
                    onInstallmentChange={handleInstallmentChange}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanCard;
