// src/components/Logo.js
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
  const logo = process.env.REACT_APP_CLIENT_LOGO || '/assets/images/cirenio.svg';

  return (
    <Link to="/">
      <img src={logo} alt="Client Logo" />
    </Link>
  );
};

export default Logo;
