// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ClientProvider } from './context/ClientContext'; // Importa el ClientProvider

// Importa tus layouts y páginas
import AuthLayout from './layouts/AuthLayout';
import PortalLayout from './layouts/PortalLayout';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage'; // Importa ProfilePage
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <Router>
      <Routes>
        {/* Rutas de autenticación */}
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>

        {/* Rutas protegidas dentro del Portal */}
        <Route
          element={
            <ProtectedRoute>
              <ClientProvider>
                <PortalLayout />
              </ClientProvider>
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<HomePage />} />
          <Route path="/clientes/:dni" element={<ProfilePage />} />
          {/* Puedes agregar más rutas aquí si es necesario */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
