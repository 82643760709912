// src/layouts/PortalLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import './PortalLayout.css';

function PortalLayout() {
  return (
    <div className="layout-horizontal">
      <Navbar />
      <div className="content-wrapper container">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default PortalLayout;
