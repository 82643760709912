import React from 'react';
import NavigationButtons from '../NavigationButtons';

const SummaryStep = ({ data = {}, onNext, onBack }) => {
  const { amount = 0, selectedPlan = {} } = data;

  // Verificar que el plan seleccionado tenga cuotas
  const cuotaAmount = selectedPlan.cuotas ? (amount / selectedPlan.cuotas).toFixed(2) : 0;

  const handleNext = () => {
    onNext();
  };

  return (
    <div>
      <h4 className="text-center mb-1">Confirmación de la compra</h4>
      <div className="loan-summary card mx-auto mb-1" style={{ maxWidth: '600px' }}>
        <div className="card-body p-2">
          <h5 className="card-title text-center">Resumen del Préstamo</h5>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <strong>Monto del préstamo:</strong> ${amount}
            </li>
            <li className="list-group-item">
              <strong>Plazo:</strong> {selectedPlan.cuotas ? `${selectedPlan.cuotas} meses` : 'No definido'}
            </li>
            {/* Puedes agregar más detalles */}
            <li className="list-group-item">
              <strong>Cuota mensual:</strong> ${cuotaAmount}
            </li>
          </ul>
        </div>
      </div>
      <NavigationButtons onBack={onBack} onNext={handleNext} nextLabel="Finalizar" />
      {/* <div className="text-center">
        <button className="btn btn-secondary me-3" onClick={onBack}>
          Atrás
        </button>
        <button className="btn btn-primary" onClick={handleNext}>
          Finalizar
        </button>
      </div> */}
    </div>
  );
};

export default SummaryStep;
