// src/components/PurchaseFlow/Step/DocumentUploadStep.js
import React, { useState } from 'react';
import { faIdCard, faIdCardAlt, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import NavigationButtons from '../../NavigationButtons';
import DocumentItem from './DocumentItem';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './DocumentUploadStep.css';

const MySwal = withReactContent(Swal);

const DocumentUploadStep = ({ onNext, onBack }) => {
  const [documents, setDocuments] = useState({
    dniFrente: { file: null, preview: null, status: 'pending' },
    dniDorso: { file: null, preview: null, status: 'pending' },
    reciboSueldo: { file: null, preview: null, status: 'pending' },
  });

  const handleFileUpload = (docType, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setDocuments((prevDocs) => ({
        ...prevDocs,
        [docType]: {
          file,
          preview: reader.result,
          status: 'uploaded',
        },
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleReject = (docType) => {
    setDocuments((prevDocs) => ({
      ...prevDocs,
      [docType]: { file: null, preview: null, status: 'pending' },
    }));
  };

  const handleNext = () => {
    // Validar que todos los documentos estén subidos
    const allUploaded = Object.values(documents).every((doc) => doc.status === 'uploaded');
    if (allUploaded) {
      onNext({ documents });
    } else {
      MySwal.fire({
        icon: 'warning',
        title: 'Información requerida',
        text: 'Por favor, sube todos los documentos requeridos.',
      });
    }
  };

  return (
    <div id="documentUploadStep" className="container my-5">
      <h4 className="text-center mb-4">Carga de Documentos</h4>
      <p className="text-center text-muted">
        Arrastra y suelta los documentos en las áreas correspondientes.
      </p>

      <div className="row justify-content-center align-items-start">
        <div className="col-md-4 text-center qr-container">
          <p>¿Prefieres continuar en el celular?</p>
          <img src="/assets/compiled/png/qr-demo.png" width={150} alt="QR Code" />
          <p className="text-muted">
            Escanea el código para continuar la carga desde tu celular
          </p>
        </div>

        <div className="col-md-8">
          <ul className="document-list">
            <DocumentItem
              icon={faIdCard}
              name="DNI Frente"
              status={documents.dniFrente.status}
              onFileUpload={(file) => handleFileUpload('dniFrente', file)}
              filePreview={documents.dniFrente.preview}
              onReject={() => handleReject('dniFrente')}
            />
            <DocumentItem
              icon={faIdCardAlt}
              name="DNI Dorso"
              status={documents.dniDorso.status}
              onFileUpload={(file) => handleFileUpload('dniDorso', file)}
              filePreview={documents.dniDorso.preview}
              onReject={() => handleReject('dniDorso')}
            />
            <DocumentItem
              icon={faFileInvoiceDollar}
              name="Recibo de Sueldo"
              status={documents.reciboSueldo.status}
              onFileUpload={(file) => handleFileUpload('reciboSueldo', file)}
              filePreview={documents.reciboSueldo.preview}
              onReject={() => handleReject('reciboSueldo')}
            />
          </ul>
        </div>
      </div>

      <NavigationButtons onBack={onBack} onNext={handleNext} />
    </div>
  );
};

export default DocumentUploadStep;
