// src/components/PaymentFlow/PaymentMethods.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faMoneyBill1Wave, faCreditCard } from '@fortawesome/free-solid-svg-icons';

const PaymentMethods = ({ selectedMethod, onMethodSelect }) => {
  const methods = [
    // { id: 'credit_card', icon: faCreditCard },
    {
      id: 'mercadopago',
      img: 'https://micuenta.cirenio.com/img/mercadopago.png',
    },
    { id: 'qr', icon: faQrcode },
    { id: 'cash', icon: faMoneyBill1Wave },
  ];

  const handleMethodClick = (method) => {
    onMethodSelect(method.id);
  };

  return (
    <div id="payment-methods" className="payment-methods">
      <h6 className="text-muted mb-3">Selecciona tu medio de pago:</h6>
      <div className="d-flex justify-content-center align-items-center">
        {methods.map((method) => (
          <div
            key={method.id}
            className={`payment-method ${
              selectedMethod === method.id ? 'selected' : ''
            }`}
            onClick={() => handleMethodClick(method)}
          >
            {method.icon && (
              <FontAwesomeIcon icon={method.icon} size="2x" />
            )}
            {method.img && (
              <img src={method.img} alt={method.id} style={{ width: '100%' }} />
            )}
          </div>
        ))}
      </div>
    </div>
  );  
};

export default PaymentMethods;
