// src/components/Profile/LoanRefinancing.js
import React, { useState, useEffect } from 'react';
import api from '../../api/api';
import Skeleton from 'react-loading-skeleton';

const LoanRefinancing = () => {
  const [loans, setLoans] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRefinancingLoans = async () => {
      try {
        const response = await api.get('refinancing');
        setLoans(response.data);
      } catch (error) {
        console.error('Error al obtener los préstamos para refinanciación:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRefinancingLoans();
  }, []);

  if (loading) {
    return <Skeleton count={3} height={100} />;
  }

  return (
    <div>
      <h3>Préstamos para Refinanciar</h3>
      {/* Mostrar lista de préstamos */}
    </div>
  );
};

export default LoanRefinancing;
