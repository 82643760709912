// src/components/PurchaseFlow/Step/ConfirmationStep.js
import React, { useState } from 'react';
import NavigationButtons from '../NavigationButtons';
import SuccessModal from '../SuccessModal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const ConfirmationStep = () => {
  const [showUploadSection, setShowUploadSection] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Función para imprimir el contrato y finalizar el proceso
  const finalizeAndPrint = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Préstamo confirmado',
      text: 'Ahora se procederá a imprimir el contrato.',
    });
    window.print();
    setShowSuccessModal(true);
  };

  // Función para imprimir el contrato y luego mostrar la opción de cargarlo
  const finalizePrintAndUpload = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Préstamo confirmado',
      text: 'Imprimiendo contrato...',
    });
    window.print();
    setShowUploadSection(true); // Mostrar la sección de carga de contrato
  };

  // Función para subir el contrato
  const uploadContract = () => {
    const fileInput = document.getElementById('contractUpload');
    const file = fileInput.files[0];

    if (file) {
      MySwal.fire({
        icon: 'success',
        text: `Contrato ${file.name} subido exitosamente.`,
      });
        // Aquí puedes agregar la lógica para subir el archivo al servidor
      setShowSuccessModal(true);
    } else {
      MySwal.fire({
        icon: 'warning',
        text: 'Por favor, seleccione un archivo de contrato para cargar.',
      });
    }
  };
  
  const handleCloseModal = () => {
    setShowSuccessModal(false);
    // Aquí puedes redirigir al inicio o realizar cualquier acción final
  };

  return (
    <div className="container my-5 text-center">
      {/* Icono de éxito */}
      <div className="success-icon mb-4">
        <i className="fas fa-check-circle" style={{ fontSize: '100px', color: 'green' }}></i>
      </div>
      <h2 className="mb-3">¡Proceso Completado Exitosamente!</h2>
      <p className="lead mb-4">El préstamo ha sido procesado, ya puedes entregar el producto.</p>

      {/* Información del préstamo */}
      <div className="loan-summary card mx-auto mb-4" style={{ maxWidth: '600px' }}>
        <div className="card-body p-2">
          <h5 className="card-title text-center">Resumen del Préstamo</h5>
          <ul className="list-group list-group-flush">
            <li className="list-group-item"><strong>Monto del préstamo:</strong> $150,000</li>
            <li className="list-group-item"><strong>Plazo:</strong> 12 meses</li>
            <li className="list-group-item"><strong>Tasa de interés:</strong> 10%</li>
            <li className="list-group-item"><strong>Cuota mensual:</strong> $13,750</li>
          </ul>
        </div>
      </div>

      {/* Botones para las acciones */}
      <div className="text-center mb-5">
        <button className="btn btn-primary btn-lg me-3" onClick={finalizeAndPrint}>
          Finalizar e Imprimir Contrato
        </button>
        <button className="btn btn-secondary btn-lg" onClick={finalizePrintAndUpload}>
          Finalizar, Imprimir y Cargar Contrato
        </button>
      </div>

      {/* Sección para cargar el contrato */}
      {showUploadSection && (
        <div id="uploadContractSection" className="mt-5">
          <h5>Cargar Contrato Firmado</h5>
          <div className="input-group mb-3">
            <input type="file" className="form-control" id="contractUpload" accept=".pdf,.docx" />
            <button className="btn btn-success" type="button" onClick={uploadContract}>
              Subir Contrato
            </button>
          </div>
        </div>
      )}

      {/* Modal de Éxito */}
      <SuccessModal show={showSuccessModal} onClose={handleCloseModal} />
    </div>
  );
};

export default ConfirmationStep;
