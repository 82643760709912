// src/components/PurchaseFlow/NavigationButtons.js
import React from 'react';

const NavigationButtons = ({ onBack, onNext, nextLabel = 'Siguiente', backLabel = 'Atrás', disableNext = false }) => {
  return (
    <div className="d-flex justify-content-between mt-4">
      {onBack ? (
        <button className="btn btn-secondary" type="button" onClick={onBack}>
          {backLabel}
        </button>
      ) : (
        <div></div>
      )}
      {onNext ? (
        <button className="btn btn-success" type="button" onClick={onNext} disabled={disableNext}>
          {nextLabel}
        </button>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default NavigationButtons;
