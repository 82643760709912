// src/components/PurchaseFlow/Step/PaymentStep.js
import React, { useEffect, useRef, useState } from 'react';
import './PaymentStep.css';
import Skeleton from 'react-loading-skeleton';
import NavigationButtons from '../../NavigationButtons';
import 'react-loading-skeleton/dist/skeleton.css';

const PaymentStep = ({ onNext, onBack, clientData }) => {
  const paymentWidgetRef = useRef(null);
  const [isTokenized, setIsTokenized] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://payments.staging.cirenio.com/js/payments-sdk.min.js';
    script.async = true;
    script.onload = () => {
      if (window.CirenioPayments) {
        const payments = new window.CirenioPayments(
          'zN3LA3Ls6fB4O4NZlVYG4mAc1RlwBLQW',
          '20351164768',
          { containerId: 'cirenioPaymentContainer' }
        );

        payments.on('widget_start', () => setIsLoading(false));
        payments.on('tokenization_success', () => setIsTokenized(true));
        payments.on('tokenization_error', () =>
          setErrorMessage('Hubo un problema al procesar tu tarjeta. Por favor, intenta nuevamente.')
        );

        payments.openWidget();
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [clientData]);

  const handleContinue = () => {
    onNext();
  };

  return (
    <div className="container my-5">
      <h4 className="text-center mb-4">Asociar Tarjeta de Débito</h4>
      <p className="text-center text-muted">
        Asocia una tarjeta de débito a tu cuenta para completar el proceso de pago.
      </p>

      <div className="row justify-content-between align-items-start gx-4">
        {/* Columna izquierda - QR */}
        <div className="col-12 col-md-5 text-center qr-container">
          <p>¿Prefieres continuar en el celular?</p>
          <img src="/assets/compiled/png/qr-demo.png" width={150} alt="QR Code" />
          <p className="text-muted">
            Escanea el código para continuar la carga desde tu celular
          </p>
        </div>

        {/* Columna derecha - Widget */}
        <div className="col-12 col-md-6">
          <div className="payment-widget-wrapper">
            {isLoading && <Skeleton height={680} />}
            <div
              id="cirenioPaymentContainer"
              ref={paymentWidgetRef}
              className="payment-widget"
              style={{ display: isLoading ? 'none' : 'block' }}
            ></div>
          </div>
        </div>
      </div>

      {errorMessage && (
        <div className="alert alert-danger text-center" role="alert">
          {errorMessage}
        </div>
      )}

      <NavigationButtons onBack={onBack} onNext={handleContinue} disableNext={!isTokenized} />
    </div>
  );
};

export default PaymentStep;
