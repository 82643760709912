import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
<footer>
    <div className="container">
        <div className="footer clearfix mb-0 text-muted">
            <div className="float-start">
                <p>2024 &copy; Cirenio</p>
            </div>
            <div className="float-end">
                <p>Powered by 
                    <Link to="https://cirenio.com">
                        <img className="ms-2 dark-hidden" src="https://cdn.cirenio.com/lenders/imgs/logo.svg?v=1725044878" height="22px" alt="Logo de Cirenio" />
                    </Link>
                </p>
            </div>
        </div>
    </div>
</footer>
  );
}

export default Footer;
