// src/components/PurchaseFlow/PurchaseFlow.js

import React, { useState, useEffect, useContext } from 'react';
import { ClientContext } from '../../context/ClientContext';

// Importar todos los componentes de pasos
import OfferStep from './Steps/Offer/OfferStep';
import DataEntryStep from './Steps/DataEntry/DataEntryStep';
import DocumentUploadStep from './Steps/DocumentUpload/DocumentUploadStep';
import SummaryStep from './Steps/SummaryStep';
import ConfirmationStep from './Steps/ConfirmationStep';
import PaymentStep from './Steps/Payment/PaymentStep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import './PurchaseFlow.css';

const PurchaseFlow = ({ onClose }) => {
  const { clientData, clientStatus } = useContext(ClientContext);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const [stepLabels, setStepsLabels] = useState([]);

  const [formData, setFormData] = useState({});

  useEffect(() => {
    const getStepsForClientStatus = () => {
      let stepsConfig = '';

      if (clientStatus === 'new_client') {
        stepsConfig = process.env.REACT_APP_PURCHASE_FLOW_NEW_CLIENT;
      } else if (clientStatus === 'new_in_commerce') {
        stepsConfig = process.env.REACT_APP_PURCHASE_FLOW_NEW_IN_COMMERCE;
      } else if (clientStatus === 'existing') {
        stepsConfig = process.env.REACT_APP_PURCHASE_FLOW_EXISTING;
      }

      console.log('stepsConfig:', stepsConfig); // Añadir log

      if (!stepsConfig) {
        console.error('stepsConfig está vacío o no está definido.');
        return;
      }

      const stepsArray = stepsConfig.split(',').map((stepName) => {
        const trimmedStepName = stepName.trim(); // Eliminar espacios
        switch (trimmedStepName) {
          case 'OfferStep':
            return OfferStep;
          case 'DataEntryStep':
            return DataEntryStep;
          case 'DocumentUploadStep':
            return DocumentUploadStep;
          case 'SummaryStep':
            return SummaryStep;
          case 'ConfirmationStep':
            return ConfirmationStep;
          case 'PaymentStep':
            return PaymentStep;
          default:
            console.warn(`Paso desconocido: "${trimmedStepName}"`);
            return null;
        }
      });

      const stepLabelsArray = stepsConfig.split(',').map((stepName) => {
        const trimmedStepName = stepName.trim(); // Eliminar espacios
        switch (trimmedStepName) {
          case 'OfferStep':
            return 'Producto';
          case 'DataEntryStep':
            return 'Datos';
          case 'DocumentUploadStep':
            return 'Documentos';
          case 'SummaryStep':
            return 'Resumen';
          case 'ConfirmationStep':
            return 'Confirmación';
          case 'PaymentStep':
            return 'Medio de Pago';
          default:
            console.warn(`Etiqueta desconocida para paso: "${trimmedStepName}"`);
            return null;
        }
      });

      console.log('stepsArray:', stepsArray);
      console.log('stepLabelsArray:', stepLabelsArray);

      setSteps(stepsArray);
      setStepsLabels(stepLabelsArray);
    };

    getStepsForClientStatus();
  }, [clientStatus]);

  const handleNext = (data) => {
    // Actualizar formData con los datos del paso actual
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        ...data,
      };
      console.log('Updated formData:', updatedData);
      return updatedData;
    });
    setCurrentStepIndex((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStepIndex((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    const CurrentStepComponent = steps[currentStepIndex];
    console.log('currentStepIndex:', currentStepIndex);
    console.log('stepLabels[currentStepIndex]:', stepLabels[currentStepIndex]);

    if (!CurrentStepComponent) {
      console.error(`Componente para el paso index ${currentStepIndex} no está definido.`);
      return <div>Error al cargar el paso. Por favor, intenta nuevamente.</div>;
    }

    return (
      <CurrentStepComponent
        clientData={clientData}
        onNext={handleNext}
        onBack={handleBack}
        formData={formData}
      />
    );
  };

  return (
    <div className="purchase-flow">
      {/* Botón para cerrar el flujo */}
      <div className="text-end">
        <button className="btn btn-link" onClick={onClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>

      {/* Stepper con barra de progreso */}
      <div className="stepper-container mb-4">
        <div className="stepper-wrapper">
          {steps.map((_, index) => {
            let stepClassName = 'stepper-item';
            if (index === currentStepIndex) {
              stepClassName += ' active';
            } else if (index < currentStepIndex) {
              stepClassName += ' completed';
            }

            return (
              <div className={stepClassName} key={index}>
                <div className="step-counter">
                  {index < currentStepIndex ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    index + 1
                  )}
                </div>
                <p className="step-label">{stepLabels[index]}</p>
              </div>
            );
          })}
        </div>
        {/* Barra de progreso */}
        <div className="progress-bar-wrapper">
          <div
            className="progress-bar"
            style={{
              width: `${((currentStepIndex) / steps.length) * 100}%`,
            }}
          ></div>
        </div>
      </div>

      {/* Renderizar el componente del paso actual */}
      {renderStep()}
    </div>
  );
};

export default PurchaseFlow;
