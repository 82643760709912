// src/components/PurchaseFlow/SuccessModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const SuccessModal = ({ show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body className="text-center">
        <div className="success-icon mb-4">
          <i className="fas fa-check-circle" style={{ fontSize: '100px', color: '#28a745' }}></i>
        </div>
        <h2 className="mb-3">¡Proceso Completado Exitosamente!</h2>
        <p className="lead mb-4">El préstamo ha sido procesado y el contrato ha sido registrado correctamente.</p>
        <Button variant="primary" size="lg" onClick={onClose}>
          Volver al Inicio
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
