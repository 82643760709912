// src/components/PurchaseFlow/Step/DocumentItem.js
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock, faTimesCircle, faEye } from '@fortawesome/free-solid-svg-icons';

const DocumentItem = ({ icon, name, status, onFileUpload, filePreview, onReject }) => {
  const [showModal, setShowModal] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileUpload(acceptedFiles[0]);
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <li className={`document-item ${status}`} {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="document-info">
        <FontAwesomeIcon icon={icon} size="2x" />
        <span className="document-name">{name}</span>
      </div>
      <div className="document-status">
        {status === 'uploaded' ? (
          <>
            <button className="btn btn-link btn-sm text-success" onClick={(e) => { e.stopPropagation(); setShowModal(true); }}>
              <FontAwesomeIcon icon={faEye} />
            </button>
            <FontAwesomeIcon icon={faCheckCircle} className="status-icon success" />
          </>
        ) : (
          <FontAwesomeIcon icon={faClock} className="status-icon pending" />
        )}
      </div>

      {/* Modal para ver y rechazar la imagen */}
      {showModal && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h5 className="modal-title">{name}</h5>
              <button className="close-button" onClick={() => setShowModal(false)}>
                &times;
              </button>
            </div>
            <div className="modal-body text-center">
              {filePreview && (
                <img
                  src={filePreview}
                  alt="Documento subido"
                  style={{ maxWidth: '100%', maxHeight: '70vh' }}
                />
              )}
            </div>
            <div className="modal-footer">
              <button className="btn btn-danger me-1" onClick={() => { onReject(); setShowModal(false); }} >
                <FontAwesomeIcon icon={faTimesCircle} /> Rechazar imagen
              </button>
              <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export default DocumentItem;
