// src/components/PurchaseFlow/Steps/DataEntry/Sections/EmploymentInfo.js
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EmploymentInfo = forwardRef((props, ref) => {
const {
    formData,
    handleChange,
    activeSection,
    sectionName,
    setSectionValidity,
    sectionValidity,
    handleSectionClick,
} = props;
const [errors, setErrors] = useState({});

const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'cuit':
        if (!value) {
          error = 'El CUIT es requerido';
        }
        break;
      case 'empresa':
        if (!value) {
          error = 'La empresa es requerida';
        }
        break;
      case 'salario':
        if (!value) {
          error = 'El salario es requerido';
        }
        break;
      case 'fechaIngreso':
        if (!value) {
          error = 'La fecha de ingreso es requerida';
        }
        break;
      case 'domicilioLaboral':
        if (!value) {
          error = 'El domicilio laboral es requerido';
        }
        break;
      case 'codigoPostalLaboral':
        if (!value) {
          error = 'El código postal laboral es requerido';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    const newErrors = {
      ...errors,
      [name]: error,
    };

    const isValid = Object.values(newErrors).every((err) => !err);
    setSectionValidity((prev) => ({ ...prev, [sectionName]: isValid }));
};

    const validateSection = () => {
        const fieldsToValidate = ['cuit', 'empresa', 'salario', 'fechaIngreso', 'domicilioLaboral', 'codigoPostalLaboral'];
        let newErrors = {};
        fieldsToValidate.forEach((field) => {
        const value = formData[field];
        if (!value) {
            newErrors[field] = 'Este campo es requerido';
        }
        });

        setErrors(newErrors);

        const isValid = Object.keys(newErrors).length === 0;
        setSectionValidity((prev) => ({ ...prev, [sectionName]: isValid }));
        return isValid;
    };
  
    useImperativeHandle(ref, () => ({
      validateSection,
    }));
  
    return (
      <div className="accordion-item">
        <h2 className="accordion-header" id={`heading${sectionName}`}>
          <button
            className={`accordion-button ${activeSection !== sectionName ? 'collapsed' : ''}`}
            type="button"
            onClick={() => handleSectionClick(sectionName)}
          >
            Datos Laborales {' '}
              {sectionValidity[sectionName] === true && (
                <FontAwesomeIcon icon={faCheckCircle} className="text-success ms-2" />
              )}
              {sectionValidity[sectionName] === false && (
                <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger ms-2" />
              )}
          </button>
        </h2>
        <div
          className={`accordion-collapse collapse ${activeSection === sectionName ? 'show' : ''}`}
          aria-labelledby={`heading${sectionName}`}
        >
          <div className="accordion-body">
            <div className="row">
              {/* Campos con validación */}
              <div className="col-md-6 mb-3">
                <label className="form-label">CUIT *</label>
                <input
                  type="text"
                  className={`form-control ${errors.cuit ? 'is-invalid' : ''}`}
                  name="cuit"
                  value={formData.cuit || ''}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  required
                />
                {errors.cuit && <div className="invalid-feedback">{errors.cuit}</div>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Empresa *</label>
                <input
                  type="text"
                  className={`form-control ${errors.empresa ? 'is-invalid' : ''}`}
                  name="empresa"
                  value={formData.empresa || ''}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  required
                />
                {errors.empresa && <div className="invalid-feedback">{errors.empresa}</div>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Salario *</label>
                <input
                  type="number"
                  className={`form-control ${errors.salario ? 'is-invalid' : ''}`}
                  name="salario"
                  value={formData.salario || ''}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  required
                />
                {errors.salario && <div className="invalid-feedback">{errors.salario}</div>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Fecha de Ingreso *</label>
                <input
                  type="date"
                  className={`form-control ${errors.fechaIngreso ? 'is-invalid' : ''}`}
                  name="fechaIngreso"
                  value={formData.fechaIngreso || ''}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  required
                />
                {errors.fechaIngreso && <div className="invalid-feedback">{errors.fechaIngreso}</div>}
              </div>
              {/* Campos opcionales */}
              <div className="col-md-6 mb-3">
                <label className="form-label">Cargo</label>
                <input
                  type="text"
                  className="form-control"
                  name="cargo"
                  value={formData.cargo || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Teléfono Laboral</label>
                <input
                  type="tel"
                  className="form-control"
                  name="telefonoLaboral"
                  value={formData.telefonoLaboral || ''}
                  onChange={handleChange}
                />
              </div>
              {/* Campos requeridos */}
              <div className="col-md-6 mb-3">
                <label className="form-label">Domicilio Laboral *</label>
                <input
                  type="text"
                  className={`form-control ${errors.domicilioLaboral ? 'is-invalid' : ''}`}
                  name="domicilioLaboral"
                  value={formData.domicilioLaboral || ''}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  required
                />
                {errors.domicilioLaboral && <div className="invalid-feedback">{errors.domicilioLaboral}</div>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Código Postal Laboral *</label>
                <input
                  type="text"
                  className={`form-control ${errors.codigoPostalLaboral ? 'is-invalid' : ''}`}
                  name="codigoPostalLaboral"
                  value={formData.codigoPostalLaboral || ''}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  required
                />
                {errors.codigoPostalLaboral && <div className="invalid-feedback">{errors.codigoPostalLaboral}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
  
  export default EmploymentInfo;  