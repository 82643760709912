// src/components/PurchaseFlow/Steps/DataEntry/Sections/PersonalInfo.js
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PersonalInfo = forwardRef((props, ref) => {
  const {
    formData,
    handleChange,
    activeSection,
    sectionName,
    sectionValidity,
    setSectionValidity,
    handleSectionClick,
  } = props;

  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'nombre':
        if (!value) {
          error = 'El nombre es requerido';
        }
        break;
      case 'apellido':
        if (!value) {
          error = 'El apellido es requerido';
        }
        break;
      case 'sexo':
        if (!value) {
          error = 'El sexo es requerido';
        }
        break;
      case 'fechaNacimiento':
        if (!value) {
          error = 'La fecha de nacimiento es requerida';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    const newErrors = {
      ...errors,
      [name]: error,
    };

    const isValid = Object.values(newErrors).every((err) => !err);
    setSectionValidity((prev) => ({ ...prev, [sectionName]: isValid }));
  };

  const validateSection = () => {
    const fieldsToValidate = ['nombre', 'apellido', 'sexo', 'fechaNacimiento'];
    let newErrors = {};
    fieldsToValidate.forEach((field) => {
      const value = formData[field];
      if (!value) {
        newErrors[field] = 'Este campo es requerido';
      }
    });

    setErrors(newErrors);

    const isValid = Object.keys(newErrors).length === 0;
    setSectionValidity((prev) => ({ ...prev, [sectionName]: isValid }));
    return isValid;
  };

  useImperativeHandle(ref, () => ({
    validateSection,
  }));

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading${sectionName}`}>
        <button
          className={`accordion-button ${activeSection !== sectionName ? 'collapsed' : ''}`}
          type="button"
          onClick={() => handleSectionClick(sectionName)}
        >
          Datos Personales{' '}
          {sectionValidity[sectionName] === true && (
            <FontAwesomeIcon icon={faCheckCircle} className="text-success ms-2" />
          )}
          {sectionValidity[sectionName] === false && (
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger ms-2" />
          )}
        </button>
      </h2>
      <div
        className={`accordion-collapse collapse ${activeSection === sectionName ? 'show' : ''}`}
        aria-labelledby={`heading${sectionName}`}
      >
        <div className="accordion-body">
          <div className="row">
            {/* Campos del formulario con validación */}
            <div className="col-md-6 mb-3">
              <label className="form-label">Nombre *</label>
              <input
                type="text"
                className={`form-control ${errors.nombre ? 'is-invalid' : ''}`}
                name="nombre"
                value={formData.nombre || ''}
                onChange={handleChange}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
                placeholder="Ingrese su nombre"
                required
              />
              {errors.nombre && <div className="invalid-feedback">{errors.nombre}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Apellido *</label>
              <input
                type="text"
                className={`form-control ${errors.apellido ? 'is-invalid' : ''}`}
                name="apellido"
                value={formData.apellido || ''}
                onChange={handleChange}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
                placeholder="Ingrese su apellido"
                required
              />
              {errors.apellido && <div className="invalid-feedback">{errors.apellido}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Sexo *</label>
              <select
                className={`form-control ${errors.sexo ? 'is-invalid' : ''}`}
                name="sexo"
                value={formData.sexo || ''}
                onChange={handleChange}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
                required
              >
                <option value="">Seleccione</option>
                <option value="masculino">Masculino</option>
                <option value="femenino">Femenino</option>
                <option value="otro">Otro</option>
              </select>
              {errors.sexo && <div className="invalid-feedback">{errors.sexo}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Fecha de Nacimiento *</label>
              <input
                type="date"
                className={`form-control ${errors.fechaNacimiento ? 'is-invalid' : ''}`}
                name="fechaNacimiento"
                value={formData.fechaNacimiento || ''}
                onChange={handleChange}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
                required
              />
              {errors.fechaNacimiento && <div className="invalid-feedback">{errors.fechaNacimiento}</div>}
            </div>
         </div>
        </div>
      </div>
    </div>
  );
});

export default PersonalInfo;
