// src/components/Navbar.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function Navbar() {
  const [dni, setDni] = useState('');
  const navigate = useNavigate();

  const redirectBasedOnDni = async () => {
    if (dni.length >= 7) {
      navigate(`/clientes/${dni}`);
    } else {
      MySwal.fire({
        icon: 'warning',
        title: 'El DNI debe tener al menos 7 caracteres.'
      });
    }
  };

  // Disparar búsqueda al presionar "Enter"
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      redirectBasedOnDni();
    }
  };

  return (
    <header className="mb-5">
      <div className="header-top">
        <div className="container d-flex align-items-center">
          <div className="logo me-3">
            <Logo />
          </div>

          {/* Búsqueda por DNI */}
          <div className="col-md-4 header-center">
            <div className="search-input input-group">
              <span className="input-group-text bg-light" id="search-document-addon">
                <i className="bi bi-person-vcard"></i>
              </span>
              <input
                type="text"
                className="form-control form-control-lg"
                id="document"
                name="document"
                placeholder="Ingrese DNI del cliente..."
                aria-label="Documento"
                aria-describedby="search-document-addon"
                value={dni}
                onChange={(e) => setDni(e.target.value)}
                onKeyDown={handleKeyDown} // Llamamos a la función al presionar "Enter"
              />
              <button className="btn icon btn-light" type="button" onClick={redirectBasedOnDni}>
                <FontAwesomeIcon icon={faMagnifyingGlass} size="2x" />
              </button>
            </div>
          </div>

          <div className="header-top-right">
            <div className="dropdown">
              <a href="#" id="topbarUserDropdown" className="user-dropdown d-flex align-items-center dropend dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <div className="avatar avatar-md2">
                  <img src="/assets/compiled/jpg/1.jpg" alt="Avatar" />
                </div>
                <div className="text">
                  <h6 className="user-dropdown-name">Juan Perez</h6>
                  <p className="user-dropdown-status text-sm text-muted">Sucursal Freyre</p>
                </div>
              </a>
              <ul className="dropdown-menu dropdown-menu-end shadow-lg" aria-labelledby="topbarUserDropdown">
                <li><a className="dropdown-item" href="#"><i className="bi bi-gear me-2"></i> Configuración</a></li>
                <li><a className="dropdown-item" href="#"><i className="bi bi-upload me-2"></i> Digitalizar documentos</a></li>
                <li><hr className="dropdown-divider" /></li>
                <li><a className="dropdown-item text-end" href="auth-login.html">Cerrar sesión <i className="bi bi-box-arrow-right me-2"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
