// src/layouts/AuthLayout.js
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import './AuthLayout.css';

const AuthLayout = () => {

  useEffect(() => {
    // Cargar el archivo /assets/css/auth.css
    const authStyle = document.createElement('link');
    authStyle.rel = 'stylesheet';
    authStyle.href = `${process.env.PUBLIC_URL}/assets/css/auth.css`;
    document.head.appendChild(authStyle);

    // Eliminarlo cuando el componente se desmonte
    return () => {
      document.head.removeChild(authStyle);
    };
  }, []);

  return (
    <div id="auth">
      <div className="row h-100">
        <div className="col-lg-5 col-12">
          <Outlet />
        </div>
        <div className="col-lg-7 d-none d-lg-block">
          <div id="auth-right">
            <img src="https://cdn.cirenio.com/brand/cirenio-logo-h_white.svg" alt="Cirenio Logo" className="cirenio-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
