// src/pages/LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/Logo';
import api from '../api/api';
import { useAuth } from '../context/AuthContext';

const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [merchantNumber, setMerchantNumber] = useState(''); // Estado para el número de comercio
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log('handleLogin called');
    try {
      const response = await api.post('/login', { username, password, merchantNumber });
      console.log('Login response:', response);
      const { token, user } = response.data;

      localStorage.setItem('authToken', token);

      login(token, user, rememberMe);

      console.log('Login successful, navigating to home');
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('Credenciales incorrectas. Por favor intenta de nuevo.');
    }
  };

  return (
    <div id="auth-left">
      <div className="auth-logo">
        <a href="/">
          <Logo />
        </a>
      </div>
      <h1 className="auth-title">Portal Comercios</h1>
      <p className="auth-subtitle mb-5">Ingresa con las credenciales que te asignamos.</p>

      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

      <form onSubmit={handleLogin}>
        <div className="form-group position-relative has-icon-left mb-4">
          <input type="text" className="form-control form-control-xl" placeholder="Número de comercio" value={merchantNumber} onChange={(e) => setMerchantNumber(e.target.value)} />
          <div className="form-control-icon">
            <i className="bi bi-shop"></i>
          </div>
        </div>
        <div className="form-group position-relative has-icon-left mb-4">
          <input
            type="text"
            className="form-control form-control-xl"
            placeholder="Usuario"
            value={username} onChange={(e) => setUsername(e.target.value)} />
          <div className="form-control-icon">
            <i className="bi bi-person"></i>
          </div>
        </div>
        <div className="form-group position-relative has-icon-left mb-4">
          <input type="password" className="form-control form-control-xl" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)}
          />
          <div className="form-control-icon">
            <i className="bi bi-shield-lock"></i>
          </div>
        </div>
        <div className="form-check form-check-lg d-flex align-items-end">
          <input className="form-check-input me-2" type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} id="flexCheckDefault" />
          <label className="form-check-label text-gray-600" htmlFor="flexCheckDefault">
            Recordarme en esta computadora
          </label>
        </div>
        <button type="submit" className="btn btn-primary btn-block btn-lg shadow-lg mt-5">
          Ingresar
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
