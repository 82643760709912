// src/api/mockApi.js
import api from './api'; // Importa tu instancia personalizada de Axios
import MockAdapter from 'axios-mock-adapter';
import axios from 'axios';

// Crear instancia de MockAdapter sobre 'api'
const mock = new MockAdapter(api, { delayResponse: 500 });

const toProperCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Mock para la solicitud POST /login
mock.onPost('/login').reply((config) => {
  const { username, password, merchantNumber } = JSON.parse(config.data);

  if (username === 'comercial' && password === '123456' && merchantNumber === '12345') {
    return [200, {
      token: 'fake-jwt-token',
      user: { id: 1, username: 'comercial', merchantNumber: '12345' },
    }];
  }

  return [401, { message: 'Credenciales incorrectas. Por favor intenta de nuevo.' }];
});

// Interceptar GET /compras
mock.onGet('/compras').reply(200, [
  {
    dni: '36611149',
    nombre: 'Isabel Suarez',
    monto: '$220k',
    fecha: '01/02/2024',
    status: 'Anulado',
  },
  {
    dni: '36611150',
    nombre: 'Carlos Rodríguez',
    monto: '$150k',
    fecha: '05/02/2024',
    status: 'Activo',
  },
  {
    dni: '36611151',
    nombre: 'Laura Martinez',
    monto: '$300k',
    fecha: '12/02/2024',
    status: 'Activo',
  },
  {
    dni: '36611152',
    nombre: 'Andrés Ramírez',
    monto: '$200k',
    fecha: '15/02/2024',
    status: 'Activo',
  },
  {
    dni: '36611153',
    nombre: 'María López',
    monto: '$450k',
    fecha: '18/02/2024',
    status: 'Anulado',
  },
  {
    dni: '36611154',
    nombre: 'Fernando Gomez',
    monto: '$180k',
    fecha: '20/02/2024',
    status: 'Activo',
  },
  {
    dni: '36611155',
    nombre: 'Germán Guerrero',
    monto: '$140k',
    fecha: '20/02/2024',
    status: 'Activo',
  },
  {
    dni: '36611156',
    nombre: 'Maria Mendez',
    monto: '$230k',
    fecha: '20/02/2024',
    status: 'Activo',
  },
  {
    dni: '36611156',
    nombre: 'Jose Luis Carballo',
    monto: '$70k',
    fecha: '20/02/2024',
    status: 'Anulado',
  },
  {
    dni: '36611156',
    nombre: 'Mariano Casarico',
    monto: '$170k',
    fecha: '20/02/2024',
    status: 'Anulado',
  }
]);

// Interceptar GET /pagos
mock.onGet('/pagos').reply(200, [
  {
    dni: '36611149',
    fecha: '01/02/2024',
    monto: '$50k',
    status: 'Pendiente',
  },
  {
    dni: '36611150',
    fecha: '05/02/2024',
    monto: '$70k',
    status: 'Pendiente',
  },
  {
    dni: '36611151',
    fecha: '10/02/2024',
    monto: '$60k',
    status: 'Completado',
  },
  {
    dni: '36611152',
    fecha: '15/02/2024',
    monto: '$100k',
    status: 'Completado',
  },
  {
    dni: '36611153',
    fecha: '18/02/2024',
    monto: '$80k',
    status: 'Completado',
  },
  {
    dni: '36611154',
    fecha: '18/02/2024',
    monto: '$25k',
    status: 'Completado',
  },
  {
    dni: '36611155',
    fecha: '18/02/2024',
    monto: '$70k',
    status: 'Completado',
  },
  {
    dni: '36611156',
    fecha: '18/02/2024',
    monto: '$80k',
    status: 'Completado',
  },
  {
    dni: '36611157',
    fecha: '18/02/2024',
    monto: '$120k',
    status: 'Anulado',
  },
  {
    dni: '36611158',
    fecha: '18/02/2024',
    monto: '$15k',
    status: 'Completado',
  }
]);

// Función auxiliar para determinar el caso según el DNI
const getClientStatusByDni = (dni) => {
  const lastDigit = parseInt(dni.slice(-1));
  if (lastDigit === 0) {
    return 'new_client';
  } else if (lastDigit % 2 === 0) {
    return 'existing';
  } else {
    return 'new_in_commerce';
  }
};

// Interceptar GET /clientes/:dni
mock.onGet(/\/clientes\/\d+/).reply(async (config) => {
  const dni = config.url.split('/').pop();
  const lastDigit = parseInt(dni.slice(-1), 10);

  try {
    // Realizar la llamada al padrón
    const padronResponse = await axios.get(`/enrich?nationalId=${dni}`);
    const padronData = padronResponse.data[0];

    if (!padronData) {
      return [404, { message: 'Cliente no encontrado en el padrón' }];
    }

    // Mantener la lógica basada en el último dígito del DNI
    let clientData = {};

    if (lastDigit === 0) {
      return [404, { message: 'Cliente no encontrado' }];
    } else if (lastDigit % 2 === 0) {
      clientData = {
        status: 'existing',
        limiteTotal: 300000,
        limiteDisponible: 170000,
        prestamos: [
          {
            numero: 12345,
            fechaEmision: '2023-01-15',
            monto: 5000,
            estado: 'Activo',
            cuotas: [
              { numero: 1, fechaVencimiento: '2023-02-15', monto: 500, estado: 'Pagada' },
              { numero: 2, fechaVencimiento: '2023-03-15', monto: 500, estado: 'Pendiente' },
            ],
          },
        ],
      };
    } else {
      clientData = {
        status: 'new_in_commerce',
        limiteTotal: 100000,
        limiteDisponible: 80000,
      };
    }

    const enrichedClientData = {
      ...clientData,
      nombre: toProperCase(padronData.nombrecompleto),
      cuil: padronData.cuit,
    };

    return [200, enrichedClientData];

  } catch (error) {
    console.error('Error al consultar el padrón:', error);
    return [500, { message: 'Error al consultar el padrón' }];
  }
});

mock.onGet(/\/offer/).reply(() => {
  return [
    200,
    [
      {
        "id": 1,
        "cuotas": 6,
        "tasaInteres": 5.0,
        "primerCuota": "15/11/2023"
      },
      {
        "id": 2,
        "cuotas": 12,
        "tasaInteres": 7.5,
        "primerCuota": "15/12/2023"
      },
      {
        "id": 3,
        "cuotas": 18,
        "tasaInteres": 10.0,
        "primerCuota": "15/01/2024"
      }
    ]
  ];
});