// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

// Crea el contexto
const AuthContext = createContext();

// Crea un hook personalizado para usar el contexto
export const useAuth = () => {
  return useContext(AuthContext);
};

// Proveedor del contexto
export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Para manejar el estado de carga inicial

  // Efecto para verificar si el usuario ya está autenticado
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setAuthToken(token);
      // Aquí podrías cargar datos adicionales del usuario si es necesario
    }
    setLoading(false); // Termina la carga inicial
  }, []);

  // Función para manejar el login
  const login = (token, userData, rememberMe) => {
    setAuthToken(token);
    setUser(userData);
    if (rememberMe) {
      localStorage.setItem('authToken', token);
    }
  };

  // Función para manejar el logout
  const logout = () => {
    setAuthToken(null);
    setUser(null);
    localStorage.removeItem('authToken');
  };

  // Valor proporcionado a los componentes consumidores
  const value = {
    authToken,
    user,
    login,
    logout,
    loading, // Incluye el estado de carga
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
