// src/context/ClientContext.js
import React, { createContext, useState } from 'react';

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [clientData, setClientData] = useState(null);
  const [clientStatus, setClientStatus] = useState(null);

  return (
    <ClientContext.Provider value={{ clientData, setClientData, clientStatus, setClientStatus }}>
      {children}
    </ClientContext.Provider>
  );
};
