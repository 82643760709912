// src/pages/ProfilePage.js
import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import './ProfilePage.css';
import { ClientContext } from '../context/ClientContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../api/api';
import padronApi from '../api/padronApi';
import PurchaseFlow from '../components/PurchaseFlow/PurchaseFlow';
import CurrentAccount from '../components/Profile/CurrentAccount';
import ClientProfile from '../components/Profile/ClientProfile';
import LoanRefinancing from '../components/Profile/LoanRefinancing';
import ProofDocuments from '../components/Profile/ProofDocuments';
import PaymentFlow from '../components/PaymentFlow/PaymentFlow';
import { useSpring, animated } from '@react-spring/web';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import HomonymModal from '../components/HomonymModal';

const ProfilePage = () => {
  const { dni } = useParams();
  const { clientData, setClientData, clientStatus, setClientStatus } = useContext(ClientContext);
  const [loading, setLoading] = useState(true);
  const [showPurchaseFlow, setShowPurchaseFlow] = useState(false);
  const [showPaymentFlow, setShowPaymentFlow] = useState(false);
  const [activeContent, setActiveContent] = useState(null);
  const [homonyms, setHomonyms] = useState([]);
  const [showHomonymModal, setShowHomonymModal] = useState(false);

  const fetchPadronData = async (dni) => {
    try {
      const response = await padronApi.get(`/enrich?nationalId=${dni}`);
      return response.data;
    } catch (error) {
      console.error('Error al consultar el padrón:', error);
      return [];
    }
  };

  function splitFullName(fullName) {
    const words = fullName.trim().toLowerCase().split(' ');
  
    let nombre = '';
    let apellido = '';
  
    if (words.length === 1) {
      nombre = words[0];
    } else if (words.length === 2) {
      apellido = words[0];
      nombre = words[1];
    } else if (words.length === 3) {
      apellido = words[0];
      nombre = words.slice(1).join(' ');
    } else if (words.length >= 4) {
      apellido = words.slice(0, 2).join(' ');
      nombre = words.slice(2).join(' ');
    }

    return {
      nombre: toProperCase(nombre),
      apellido: toProperCase(apellido),
    };
  }
  
  function toProperCase(text) {
    return text
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }  

  function determineClientStatusAndLimits(dni) {
    const dniLastDigit = parseInt(dni.slice(-1), 10);
    const isExistingClient = dniLastDigit % 2 === 0;

    let limiteTotal;
    let limiteDisponible;

    if (isExistingClient) {
      limiteTotal = 100000;
      const min = 10000;
      const max = 70000;
      const randomMultiple = Math.floor(Math.random() * ((max - min) / 1000 + 1)) * 1000 + min;
      limiteDisponible = randomMultiple;
    } else {
      limiteTotal = 50000;
      limiteDisponible = 50000;
    }

    const clientStatus = isExistingClient ? 'existing' : 'new_client';

    return { limiteTotal, limiteDisponible, clientStatus };
  }

  const processPadronClientData = (clientFromPadron) => {
    const { nombre, apellido } = splitFullName(clientFromPadron.nombrecompleto);
  
    // Mapear el sexo
    const sexoMap = {
      'M': 'masculino',
      'F': 'femenino',
    };
    const sexo = sexoMap[clientFromPadron.sexo] || 'otro';
  
    // Obtener límites y estado del cliente
    const { limiteTotal, limiteDisponible, clientStatus } = determineClientStatusAndLimits(clientFromPadron.dni);

    // Actualizar clientData
    const clientDataFromPadron = {
      cuit: clientFromPadron.cuit,
      nombre,
      apellido,
      fechaNacimiento: clientFromPadron.fechanacimiento,
      sexo,
      dni: clientFromPadron.dni,
      limiteTotal,
      limiteDisponible,
    };
  
    setClientData(clientDataFromPadron);
    setClientStatus(clientStatus);
    setLoading(false);
  };
  
  const fetchClientProfile = async (dni) => {
    setLoading(true);
    const padronData = await fetchPadronData(dni);
  
    if (padronData.length === 1) {
      processPadronClientData(padronData[0]);
    } else if (padronData.length > 1) {
      setHomonyms(padronData);
      setShowHomonymModal(true);
    } else {
      // Si no se encuentra en el padrón
      const { limiteTotal, limiteDisponible, clientStatus } = determineClientStatusAndLimits(dni);

      setClientData({
        nombre: 'Cliente',
        apellido: 'Nuevo',
        cuil: dni,
        limiteTotal,
        limiteDisponible,
      });
      setLoading(false);
    }
  };
  
  const handleSelectClient = (client) => {
    setShowHomonymModal(false);
    processPadronClientData(client);
  };

  useEffect(() => {
    setShowPurchaseFlow(false);
    setShowPaymentFlow(false);
    setActiveContent(null);
    fetchClientProfile(dni);
  }, [dni]);

  const clientPermissions = {
    new_client: {
      canPurchase: true,
      canViewCurrentAccount: false,
      canRefinance: false,
      canViewProofDocuments: false,
      canMakePayments: false,
      canEditProfile: true,
    },
    new_in_commerce: {
      canPurchase: true,
      canViewCurrentAccount: false,
      canRefinance: false,
      canViewProofDocuments: false,
      canMakePayments: false,
      canEditProfile: true,
    },
    existing: {
      canPurchase: true,
      canViewCurrentAccount: true,
      canRefinance: false,
      canViewProofDocuments: false,
      canMakePayments: true,
      canEditProfile: true,
    },
  };

  const permissions = clientPermissions[clientStatus] || {};

  const percentage = useMemo(() => {
    if (!clientData || clientData.limiteTotal === 0) return 0;
    const disponible = parseFloat(clientData.limiteDisponible);
    const total = parseFloat(clientData.limiteTotal);
    const calculatedPercentage = (disponible / total) * 100;
    console.log('Calculated Percentage:', calculatedPercentage);
    return calculatedPercentage;
  }, [clientData]);  
  
  const progressColor = useMemo(() => {
    if (percentage < 10) return '#ff0000'; // Red
    if (percentage < 30) return '#ffc107'; // Yellow
    return '#198722'; // Green
  }, [percentage]);

  const [animatedValue, apiC] = useSpring(() => ({
    value: 0,
    config: { duration: 800 },
  }));

  useEffect(() => {
    console.log('Starting animation with value:', percentage);
    apiC.start({ value: percentage });
  }, [percentage, apiC]);
  
  const renderProgressBar = () => (
    <animated.div style={{ width: '100%', height: '100%' }}>
      <CircularProgressbarWithChildren
        value={animatedValue.value.to((v) => Math.round(v))}
        maxValue={100}
        styles={buildStyles({
          pathColor: progressColor,
          trailColor: '#d6d6d6',
        })}
      >
        <div style={{ textAlign: 'center' }}>
          <p className="text-secondary" style={{ margin: 0, fontSize: '14px' }}>Disponible</p>
          <strong style={{ fontSize: '18px' }}>
            ${clientData ? clientData.limiteDisponible : 0}
          </strong>
        </div>
      </CircularProgressbarWithChildren>
    </animated.div>
  );
  
  const startPurchaseFlow = () => {
    setShowPurchaseFlow(true);
    setActiveContent(null);
    setShowPaymentFlow(false);
  };

  const closePurchaseFlow = () => {
    setShowPurchaseFlow(false);
  };

  const startPaymentFlow = () => {
    setShowPaymentFlow(true);
    setActiveContent(null);
    setShowPurchaseFlow(false);
  };

  const closePaymentFlow = () => {
    setShowPaymentFlow(false);
  };

  const renderActiveContent = () => {
    if (showPurchaseFlow) {
      return (
        <PurchaseFlow
          clientStatus={clientStatus}
          maxAmount={clientData.limiteDisponible}
          onClose={closePurchaseFlow}
        />
      );
    }

    if (showPaymentFlow) {
      return <PaymentFlow clientData={clientData} onClose={closePaymentFlow} />;
    }

    switch (activeContent) {
      case 'CurrentAccount':
        return <CurrentAccount />;
      case 'ClientProfile':
        return <ClientProfile />;
      case 'LoanRefinancing':
        return <LoanRefinancing />;
      case 'ProofDocuments':
        return <ProofDocuments />;
      default:
        return <p>Selecciona una opción para comenzar.</p>;
    }
  };

  if (loading || showHomonymModal) {
    return (
      <div className="page-content">
        {showHomonymModal && (
          <HomonymModal homonyms={homonyms} onSelectClient={handleSelectClient} onClose={() => setShowHomonymModal(false)} />
        )}
        <div className="row">
          <div className="col-12 col-lg-3">
            <div className="card profile-card text-center">
              <div className="card-body">
                <Skeleton circle={true} height={100} width={100} className="mb-3" />
                <h3><Skeleton width={120} /></h3>
                <p className="text-muted"><Skeleton width={160} /></p>
                <Skeleton width={150} height={30} />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-9">
            <div className="row">
              <div className="col-md-6">
                <div className="card btn-card shadow mb-1" >
                  <div className="card-body text-center">
                    <Skeleton circle={true} height={50} width={50} className="mb-3" />
                    <h5 className="card-title mt-2"><Skeleton width={100} height={20} /></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card btn-card shadow mb-1" >
                  <div className="card-body text-center">
                    <Skeleton circle={true} height={50} width={50} className="mb-3" />
                    <h5 className="card-title mt-2"><Skeleton width={100} height={20} /></h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-2">
              <div className="card-body">
                <p><Skeleton height={40} /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-content">
      <div className="page-heading">
        <div className="row">
          <div className="col-12 col-lg-3">
            <div className="card profile-card text-center">
              <div className="card-body">
              <div className="avatar avatar-2xl mb-3">
                <img src={clientData.sexo === 'femenino' ? '/assets/compiled/jpg/female-profile.jpg' : '/assets/compiled/jpg/male-profile.jpg'}  alt="Avatar"  className="rounded-circle" />
              </div>
                <h3 className="mt-2">{clientData.apellido}, {clientData.nombre}</h3>
                <p className="text-muted mb-2">CUIL: {clientData.cuit}</p>
                <div className="mt-4 mb-4" style={{ width: '150px', margin: '0 auto', position: 'relative', }} >
                  {renderProgressBar()}
                </div>
                <div className="financial-info mt-4">
                  <h6>Crédito Total: ${clientData.limiteTotal}</h6>
                </div>
                <hr className="my-4" />
                <h6 className="text-start">Navegación</h6>
                <div className="opciones-perfil mt-2 text-start">
                  <div
                    className="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {permissions.canViewCurrentAccount && (
                      <button
                        className={`nav-link text-start ${
                          activeContent === 'CurrentAccount' ? 'active' : ''
                        }`}
                        type="button"
                        onClick={() => {
                          setActiveContent('CurrentAccount');
                          setShowPurchaseFlow(false);
                          setShowPaymentFlow(false);
                        }}
                      >
                        <i className="bi bi-wallet2 me-2"></i> Cuenta Corriente
                      </button>
                    )}
                    {permissions.canEditProfile && (
                      <button className={`nav-link text-start ${ activeContent === 'ClientProfile' ? 'active' : '' }`} type="button" onClick={() => { setActiveContent('ClientProfile'); setShowPurchaseFlow(false); setShowPaymentFlow(false); }} >
                        <i className="bi bi-person me-2"></i> Legajo Digital
                      </button>
                    )}
                    {permissions.canRefinance && (
                      <button
                        className={`nav-link text-start ${
                          activeContent === 'LoanRefinancing' ? 'active' : ''
                        }`}
                        type="button"
                        onClick={() => {
                          setActiveContent('LoanRefinancing');
                          setShowPurchaseFlow(false);
                          setShowPaymentFlow(false);
                        }}
                      >
                        <i className="bi bi-arrow-repeat me-2"></i>{' '}
                        Refinanciaciones
                      </button>
                    )}
                    {permissions.canViewProofDocuments && (
                      <button
                        className={`nav-link text-start ${
                          activeContent === 'ProofDocuments' ? 'active' : ''
                        }`}
                        type="button"
                        onClick={() => {
                          setActiveContent('ProofDocuments');
                          setShowPurchaseFlow(false);
                          setShowPaymentFlow(false);
                        }}
                      >
                        <i className="bi bi-file-earmark-text me-2"></i>{' '}
                        Constancias
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-9">
            <div className="row">
              {permissions.canPurchase && (
                <div className="col-md-6">
                  <div className={`card btn-card shadow mb-1 main-action ${showPurchaseFlow ? 'selected' : ''}`} id="purchase" style={{ cursor: 'pointer' }} title="Inicia una nueva compra para el cliente." onClick={startPurchaseFlow} >
                    <div className="card-body text-center">
                      <i className="bi bi-cart text-success display-5"></i>
                      <h5 className="card-title mt-2 text-success">Nueva Compra</h5>
                    </div>
                  </div>
                </div>
              )}
              {permissions.canMakePayments && (
                <div className="col-md-6">
                  <div className={`card btn-card shadow mb-1 main-action ${showPaymentFlow ? 'selected' : ''}`} id="payment" style={{ cursor: 'pointer' }} title="Realiza pagos en nombre del cliente." onClick={startPaymentFlow} >
                    <div className="card-body text-center">
                      <i className="bi bi-credit-card text-secondary display-5"></i>
                      <h5 className="card-title mt-2 text-secondary">Cobrar Cuotas</h5>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="card mt-2">
              <div className="card-body" id="content-area">
                {renderActiveContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
