// src/components/Profile/ImageDropzone.js
import React from 'react';
import { useDropzone } from 'react-dropzone';

const ImageDropzone = ({ field, handleDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, field),
  });

  return (
    <div
      {...getRootProps()}
      className="dropzone"
      style={{
        border: '2px dashed #ced4da',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <input {...getInputProps()} />
      <p>Arrastra un archivo aquí o haz clic para subir uno nuevo</p>
    </div>
  );
};

export default ImageDropzone;
