// src/components/PaymentFlow/LoanList.js
import React from 'react';
import LoanCard from './LoanCard';
import Skeleton from 'react-loading-skeleton';

const LoanList = ({ loans, onInstallmentSelection, loading }) => {
  if (loading) {
    return (
      <>
        {[1, 2, 3].map((n) => (
          <div key={n} className="mb-3">
            <Skeleton height={200} />
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      {loans.map((loan) => (
        <LoanCard
          key={loan.id}
          loan={loan}
          onInstallmentSelection={onInstallmentSelection}
        />
      ))}
    </>
  );
};

export default LoanList;
