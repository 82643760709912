// src/components/PurchaseFlow/Step/OfferStep.js
import React, { useState } from 'react';
import api from '../../../../api/api';
import Skeleton from 'react-loading-skeleton';
import NavigationButtons from '../../NavigationButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlender, faMobileAlt, faHome, faLaptop } from '@fortawesome/free-solid-svg-icons';
import 'react-loading-skeleton/dist/skeleton.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './OfferStep.css';

const MySwal = withReactContent(Swal);

const OfferStep = ({ maxAmount = 10000, onNext }) => {
  const [amount, setAmount] = useState('');
  const [maxAmountState, setMaxAmount] = useState(maxAmount); // Valor predeterminado si maxAmount es undefined
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const categories = [
    { id: 'small-appliances', label: 'Pequeño Electro', icon: faBlender },
    { id: 'cellphones', label: 'Celular', icon: faMobileAlt },
    { id: 'home-appliances', label: 'Línea Blanca', icon: faHome },
    { id: 'tecno', label: 'Tecnología', icon: faLaptop },
  ];

  const fetchPlans = async () => {
    if (amount && amount > 0 && amount <= maxAmountState && selectedCategory) {
      setLoading(true);
      try {
        const response = await api.get('/offer');
        setPlans(response.data);
      } catch (error) {
        console.error('Error al obtener los planes:', error);
      } finally {
        setLoading(false);
      }
    } else {
      MySwal.fire({
        icon: 'warning',
        title: 'Datos incompletos',
        text: `Por favor, selecciona una categoría y un monto válido (hasta $${maxAmountState}).`,
      });
    }
  };

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handleNext = () => {
    if (selectedPlan) {
      onNext({ amount, selectedPlan });
    } else {
      MySwal.fire({
        icon: 'warning',
        title: 'Selección requerida',
        text: 'Por favor, selecciona un plan.',
      });
    }
  };

  const handleRequestMoreCredit = () => {
    const additionalAmountLimit = maxAmountState * 0.25; // 25% adicional
    MySwal.fire({
      title: 'Solicitar más cupo',
      html: `
        <p>Puedes solicitar hasta $${additionalAmountLimit.toFixed(2)} adicionales.</p>
        <input type="number" id="requestedAmount" class="swal2-input" placeholder="Monto adicional">
        <input type="password" id="commerceKey" class="swal2-input" placeholder="Clave de comercio">
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Solicitar',
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
        const requestedAmount = parseFloat(document.getElementById('requestedAmount').value);
        const commerceKey = document.getElementById('commerceKey').value;

        if (!requestedAmount || requestedAmount <= 0) {
          MySwal.showValidationMessage('Por favor, ingresa un monto válido.');
          return false;
        }

        if (requestedAmount > additionalAmountLimit) {
          MySwal.showValidationMessage('El monto solicitado excede el límite permitido. \n  Por favor, contacta a soporte.');
          return false;
        }

        if (!commerceKey) {
          MySwal.showValidationMessage('Por favor, ingresa la clave de comercio.');
          return false;
        }

        // Aquí podrías verificar la clave de comercio si es necesario

        return { requestedAmount, commerceKey };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { requestedAmount } = result.value;

        // Aquí puedes realizar las acciones necesarias para procesar la solicitud
        // Por ejemplo, llamar a una API para actualizar el maxAmount

        const newMaxAmount = maxAmountState + requestedAmount;
        setMaxAmount(newMaxAmount);

        MySwal.fire({
          icon: 'success',
          title: 'Solicitud aprobada',
          text: `Tu nuevo cupo máximo es de $${newMaxAmount.toFixed(2)}.`,
        });
      }
    });
  };

  const renderSkeletons = () => {
    return (
      <div className="row justify-content-center mt-5">
        {[...Array(3)].map((_, index) => (
          <div className="col-md-4" key={index}>
            <div className="card offer-option">
              <div className="card-body text-center">
                <Skeleton height={30} width={150} />
                <Skeleton height={20} width={100} className="mt-2" />
                <Skeleton height={20} width={180} className="mt-2" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="row pt-3 d-flex justify-content-center">
      <h4 className="text-center mb-4">¿Qué producto vas a comprar?</h4>

      {/* Selector de Categorías */}
      {!selectedCategory ? (
        <div className="row justify-content-center">
          {categories.map((category) => (
            <div className="col-md-3" key={category.id}>
              <div
                className={`card category-option ${selectedCategory === category.id ? 'selected' : ''}`}
                onClick={() => setSelectedCategory(category.id)}
                style={{ cursor: 'pointer' }}
              >
                <div className="card-body text-center">
                  <FontAwesomeIcon icon={category.icon} size="2x" className="mb-3" />
                  <h5 className="card-title">{category.label}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="col-md-8">
          <div className="input-group">
            <span className="input-group-text"><i className="fas fa-dollar-sign"></i></span>
            <input
              type="number"
              className="form-control"
              id="amount"
              name="amount"
              placeholder="¿Cuál es el precio?"
              required
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <button className="btn btn-primary" type="button" onClick={fetchPlans}>
              Calcular Cuotas
            </button>
          </div>
          <p>
            <small className="text-muted">
              Monto máximo disponible: ${maxAmountState}.{' '}
              <a href="#" onClick={(e) => { e.preventDefault(); handleRequestMoreCredit(); }}>Solicitar más cupo</a>
            </small>
          </p>
        </div>
      )}

      {loading ? (
        renderSkeletons()
      ) : (
        plans.length > 0 && (
          <>
            <div id="plansContainer" className="row justify-content-center mt-5">
              {plans.map((plan) => {
                const cuotaAmount = (amount / plan.cuotas).toFixed(2);
                return (
                  <div className="col-md-4" key={plan.id}>
                    <div
                      className={`card offer-option ${selectedPlan === plan ? 'selected' : ''}`}
                      onClick={() => selectPlan(plan)}
                    >
                      <div className="card-body text-center">
                        <h4 className="card-title">{plan.cuotas} Cuotas</h4>
                        <p className="card-text">${cuotaAmount} por cuota</p>
                        <p className="card-text">Primera cuota: {plan.primerCuota}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <NavigationButtons onNext={handleNext} nextLabel="Continuar" />
          </>
        )
      )}
    </div>
  );
};

export default OfferStep;
