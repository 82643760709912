// src/components/PaymentFlow/PaymentSummary.js

import React from 'react';

const PaymentSummary = ({ selectedInstallments }) => {
  return (
    <ul className="list-group list-group-flush carrito-scroll">
      {selectedInstallments.map((item, index) => (
        <li key={index} className="list-group-item d-flex justify-content-between" style={{ fontSize: '0.9rem' }} >
          <span>
            Pr#{item.loanId} <br/>
            Cuota {item.installment.installmentNumber}
          </span>
          <span className="text-primary">
            ${parseFloat(item.amount).toFixed(2)}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PaymentSummary;
