// src/components/PurchaseFlow/Steps/DataEntry/DataEntryStep.js
import React, { useState, useRef } from 'react';
import NavigationButtons from '../../NavigationButtons';
import PersonalInfo from './Sections/PersonalInfo';
import AddressContact from './Sections/AddressContact';
import Referrals from './Sections/Referrals';
import EmploymentInfo from './Sections/EmploymentInfo';

const DataEntryStep = ({ onNext, onBack, formData: initialData = {}, clientData }) => {
  const [formData, setFormData] = useState({
    ...initialData,
    nombre: initialData.nombre || clientData.nombre || '',
    apellido: initialData.apellido || clientData.apellido || '',
    fechaNacimiento: initialData.fechaNacimiento || clientData.fechaNacimiento || '',
    sexo: initialData.sexo || clientData.sexo || '',
  });

  const [activeSection, setActiveSection] = useState('PersonalInfo');
  const [sectionValidity, setSectionValidity] = useState({
    PersonalInfo: null,
    AddressContact: null,
    Referrals: null,
    EmploymentInfo: null,
  });

  const personalInfoRef = useRef();
  const addressContactRef = useRef();
  const referralsRef = useRef();
  const employmentInfoRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSectionClick = (targetSection) => {
    let currentSectionRef;

    switch (activeSection) {
      case 'PersonalInfo':
        currentSectionRef = personalInfoRef;
        break;
      case 'AddressContact':
        currentSectionRef = addressContactRef;
        break;
      case 'Referrals':
        currentSectionRef = referralsRef;
        break;
      case 'EmploymentInfo':
        currentSectionRef = employmentInfoRef;
        break;
      default:
        currentSectionRef = null;
    }

    if (currentSectionRef && currentSectionRef.current) {
      currentSectionRef.current.validateSection();
    }

    if (activeSection === targetSection) {
      setActiveSection(null);
    } else {
      setActiveSection(targetSection);
    } 
  };

  const handleNext = () => {
    // Validar la sección actual antes de avanzar
    let currentSectionRef;

    switch (activeSection) {
      case 'PersonalInfo':
        currentSectionRef = personalInfoRef;
        break;
      case 'AddressContact':
        currentSectionRef = addressContactRef;
        break;
      case 'Referrals':
        currentSectionRef = referralsRef;
        break;
      case 'EmploymentInfo':
        currentSectionRef = employmentInfoRef;
        break;
      default:
        currentSectionRef = null;
    }

    if (currentSectionRef && currentSectionRef.current && !currentSectionRef.current.validateSection()) {
      console.warn('No puede avanzar sin completar todos los campos requeridos.');
      return;
    }

    if (Object.values(sectionValidity).every((isValid) => isValid === true)) {
      onNext({ personalInfo: formData });
    } else {
      console.warn('Por favor, complete todas las secciones antes de continuar.');
    }
  };

  return (
    <div className="container my-5">
      <h4 className="text-center mb-4">Complete sus Datos</h4>

      {/* Accordion con formularios */}
      <div className="accordion" id="datosAccordion">
        <PersonalInfo
          ref={personalInfoRef}
          formData={formData}
          handleChange={handleChange}
          activeSection={activeSection}
          sectionName="PersonalInfo"
          sectionValidity={sectionValidity}
          setSectionValidity={setSectionValidity}
          handleSectionClick={handleSectionClick}
        />
        <AddressContact
          ref={addressContactRef}
          formData={formData}
          handleChange={handleChange}
          activeSection={activeSection}
          sectionName="AddressContact"
          sectionValidity={sectionValidity}
          setSectionValidity={setSectionValidity}
          handleSectionClick={handleSectionClick}
        />
        <Referrals
          ref={referralsRef}
          formData={formData}
          handleChange={handleChange}
          activeSection={activeSection}
          sectionName="Referrals"
          sectionValidity={sectionValidity}
          setSectionValidity={setSectionValidity}
          handleSectionClick={handleSectionClick}
        />
        <EmploymentInfo
          ref={employmentInfoRef}
          formData={formData}
          handleChange={handleChange}
          activeSection={activeSection}
          sectionName="EmploymentInfo"
          sectionValidity={sectionValidity}
          setSectionValidity={setSectionValidity}
          handleSectionClick={handleSectionClick}
        />
      </div>

      <NavigationButtons
        onBack={onBack}
        onNext={handleNext}
        disableNext={!Object.values(sectionValidity).every((isValid) => isValid === true)}
      />
    </div>
  );
};

export default DataEntryStep;
